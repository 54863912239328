import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Torvalds släpper Kernel 5.6: `}<a parentName="li" {...{
          "href": "https://lkml.org/lkml/2020/3/29/379"
        }}>{`https://lkml.org/lkml/2020/3/29/379`}</a>{` `}<a parentName="li" {...{
          "href": "https://www.phoronix.com/scan.php?page=news_item&px=Linux-5.6-The-Best-Features"
        }}>{`https://www.phoronix.com/scan.php?page=news_item&px=Linux-5.6-The-Best-Features`}</a></li>
      <li parentName="ul">{`WSL2: `}<a parentName="li" {...{
          "href": "https://linux.slashdot.org/story/20/03/16/0137200/windows-subsystem-for-linux-2-will-include-a-microsoft-written-linux-kernel"
        }}>{`https://linux.slashdot.org/story/20/03/16/0137200/windows-subsystem-for-linux-2-will-include-a-microsoft-written-linux-kernel`}</a></li>
      <li parentName="ul">{`Purism Librem Mini: `}<a parentName="li" {...{
          "href": "https://puri.sm/products/librem-mini/"
        }}>{`https://puri.sm/products/librem-mini/`}</a></li>
      <li parentName="ul">{`Plasma Mobile behöver hjälp med 1.0: `}<a parentName="li" {...{
          "href": "https://www.plasma-mobile.org/2020/04/02/Plasma-Mobile-How-to-help.html"
        }}>{`https://www.plasma-mobile.org/2020/04/02/Plasma-Mobile-How-to-help.html`}</a></li>
      <li parentName="ul">{`Half Life Alyx natively på Linux med Vulkan: `}<a parentName="li" {...{
          "href": "https://www.gamingonlinux.com/articles/half-life-alyx-is-confirmed-for-linux-to-arrive-with-vulkan-support-post-release.16244"
        }}>{`https://www.gamingonlinux.com/articles/half-life-alyx-is-confirmed-for-linux-to-arrive-with-vulkan-support-post-release.16244`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Zoom, säkert eller osäkert?: `}<a parentName="li" {...{
          "href": "https://blog.rapid7.com/2020/04/02/dispelling-zoom-bugbears-what-you-need-to-know-about-the-latest-zoom-vulnerabilities/"
        }}>{`https://blog.rapid7.com/2020/04/02/dispelling-zoom-bugbears-what-you-need-to-know-about-the-latest-zoom-vulnerabilities/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Firefox Better Web: `}<a parentName="li" {...{
          "href": "https://firstlook.firefox.com/betterweb/"
        }}>{`https://firstlook.firefox.com/betterweb/`}</a></li>
      <li parentName="ul">{`Plasma Bigscreen: `}<a parentName="li" {...{
          "href": "https://plasma-bigscreen.org"
        }}>{`https://plasma-bigscreen.org`}</a>{` (tack Marcus på telegram)`}</li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Kommande`}</h3>
    <ul>
      <li parentName="ul">{`Vim`}<ul parentName="li">
          <li parentName="ul">{`plugins: "neo vim" / "vim" / annat?`}</li>
          <li parentName="ul">{`Två veckor konstant, no exceptions`}</li>
        </ul></li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Finns vi personligen på sociala medier (undrar Hund)?`}</li>
      <li parentName="ul">{`Vad Trevlig Mjukvara har gjort under "semestern"`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      